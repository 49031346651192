<div class="flex flex-column min-h-100dvh md:flex-row">
  <!-- Navbar -->
  <rc-ui-navbar-vertical
    class="hidden md:flex sticky top-0 h-100dvh shadow-2 z-2"
    [linksPrimary]="linksPrimary"
    [linksSecondary]="linksSecondary"
    borderSide="right"
  ></rc-ui-navbar-vertical>
  <rc-ui-navbar-horizontal
    class="md:hidden sticky top-0 z-2"
    [linksPrimary]="linksPrimary"
    [linksSecondary]="linksSecondary"
  ></rc-ui-navbar-horizontal>

  <!-- Content Container -->
  <div class="p-5 surface-ground text-600 flex-grow-1">
    <router-outlet></router-outlet>
  </div>
</div>
