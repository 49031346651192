import { Component, Input } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeIcons } from 'primeng/api';
import { KindeAuthService } from '../../../auth/kinde/kinde-auth.service';
import { NavbarLinksComponent, NavLink } from '../../components/navbar';
import { RcUiNavbarHorizontalComponent } from '../../components/navbar/horizontal/horizontal.component';
import { RcUiNavbarVerticalComponent } from '../../components/navbar/vertical/vertical.component';

export type RcUiSideNavLinks = Array<NavLink>;

@Component({
  selector: 'rc-ui-side-nav-layout',
  templateUrl: './side-nav.component.html',
  styleUrl: './side-nav.component.scss',
  standalone: true,
  imports: [
    RcUiNavbarVerticalComponent,
    RcUiNavbarHorizontalComponent,
    RouterOutlet,
  ],
})
export class RcUiSideNavLayoutComponent implements NavbarLinksComponent {
  constructor(private authService: KindeAuthService) {}

  @Input({ required: true }) public linksPrimary!: RcUiSideNavLinks;

  public readonly linksSecondary?: RcUiSideNavLinks = [
    {
      label: 'Logout',
      //route: '/logout',
      onClick: () => this.authService.redirectToLogout(),
      icon: PrimeIcons.SIGN_OUT,
    },
  ];
}
